<template>
  <section>
    <h2 class="titulo-links-escolha">CADASTRAR PERÍODO</h2>
    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12 lg:col-3">
            <label>Nome do Período</label>
            <pm-InputText  v-model="serieInfo.nome" :class="{'p-invalid': validationErrors.nome}" />
            <small v-show="validationErrors.nome" class="p-error">O nome do Período é obrigatório!</small>
          </div>
          <div class="field col-12 md:col-12 lg:col-3">
            <label>Selecione o Segmento</label>
            <select                   @change="mudarSelecionado(serieInfo.segmento)"
                  class="p-inputtext p-component" v-model="serieInfo.segmento"
              style="appearance: revert !important;width: 100% !important;" :disabled="perguntasCreche == true" >
                <option v-for="n in segmentosALL" :key="n" :value="n"  >{{n.nome}}</option>
            </select>
          </div>

          <div class="field col-12"></div>

          <div class="field col-12 md:col-12">
             <fieldset :disabled="perguntasCreche == true">
              <h6 v-if="serieInfo.segmento">Selecione o tipo:</h6>
              <va-radio
                option="option1"
                v-model="radioSelectedOption"
                label="Dois Períodos, com recuperação paralela no 1° e no 2°, possui Recuperação Final na segunda tabela."
                v-if="serieInfo.segmento.tipo == 3"
              />

              <va-radio
                option="option2"
                v-model="radioSelectedOption"
                label="Dois Períodos, com recuperação paralela no 1° e no 2°, possui Recuperação Final na última tabela."
                v-if="serieInfo.segmento.tipo == 3"
              />

              <va-radio
                option="option1"
                v-model="radioSelectedOption"
                label="Normal"
                v-if="serieInfo.segmento.tipo == 1 || serieInfo.segmento.tipo == 2"
              />

              <va-radio
                option="option3"
                v-model="radioSelectedOption"
                label="Formulário de perguntas e respostas(apenas para educação infantil)"
                v-if="serieInfo.segmento.tipo == 0"
              />
              </fieldset>
          </div>
          <div class="col-md-12" v-if="serieInfo.segmento.tipo != undefined && serieInfo.segmento.tipo == 1 || (serieInfo.segmento.tipo == 3 && radioSelectedOption == 'option1')">
            <label
            >Esse período possui mais de um professor?
            <pm-InputSwitch
              class="small-switch"
              v-model="serieInfo.possui_mais_de_um_professor"
              style="vertical-align: middle !important"
          /></label>
          </div>
        </div>

        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />
          <pm-Button label="Cadastrar" class="p-button-success" @click="cadastrar"
            :disabled="perguntasCreche == true"  v-if="serieInfo.nome != null && serieInfo.nome != '' &&  serieInfo.segmento != ''"
             icon="pi pi-check" iconPos="right"  />
        </div>
      </template>
    </pm-Card>
  </section>
  <section v-if="perguntasCreche">
    <h2 class="titulo-links-escolha" v-if="editarPerguntaHabilitado == 0"> ADICIONAR PERGUNTAS</h2>
    <h2 class="titulo-links-escolha" v-if="editarPerguntaHabilitado == 1"> EDITAR PERGUNTAS</h2>
    <div class="form-elements">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="$t('forms.inputs.title')">
            <va-card-content>
              <form>
                <div class="row">
                  <div class="field col-12 md:col-12">
                    <div class="form-group">
                      <label for="comment" style="padding-top:15px">Digite a pergunta:</label>
                      <br><br>
                      <textarea class="form-control" rows="4"   v-model="crechePerguntasInfo.pergunta"></textarea>
                    </div>
                    <br>
                  </div>

                  <div class="field col-12 md:col-12">
                    <h6>A pergunta é de multipla escolha?</h6>
                    <fieldset>
                      <va-radio
                        option="option1"
                        v-model="radioSelectedOption1"
                        label="Sim"
                      />
                      <va-radio
                        option="option2"
                        v-model="radioSelectedOption1"
                        label="Não"
                      />
                    </fieldset>
                  </div>
                  <div class="field col-12 md:col-2" >
                    <pm-Button label="Adicionar nova pergunta"
                      :disabled="crechePerguntasInfo.pergunta == ''"
                      @click="enviarPergunta"  v-if="serieInfo.nome != null && serieInfo.nome != '' &&  serieInfo.segmento != '' && editarPerguntaHabilitado == 0"
                    />

                    <pm-Button label="Editar  pergunta"
                      :disabled="crechePerguntasInfo.pergunta == ''"
                      @click="editarPergunta"
                      v-if="serieInfo.nome != null && serieInfo.nome != '' &&  serieInfo.segmento != '' && editarPerguntaHabilitado == 1"
                    />
                  </div>
                </div>
              </form>
            </va-card-content>
          </va-card>
        </div>
      </div>
      <br>
    </div>

    <div class="pm-card">
      <pm-DataTable :value="listaPerguntas"   dataKey="id" :rowHover="true" filterDisplay="menu"
         responsiveLayout="scroll">

          <pm-Column field="pergunta" sortable header="PERGUNTA" style="font-size: 14px;vertical-align:middle !important"></pm-Column>
          <pm-Column field="multipla_escolha"  header="A PERGUNTA É DE MULTIPLAS ESCOLHAS?" style="font-size: 14px;vertical-align:middle !important"></pm-Column>

          <pm-Column field="all" headerStyle="width: 4rem; text-align: center"
             bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
            <template #body="{data}">

              <pm-Button icon="pi pi-user-edit" style="margin-right: 10px;"
                label="Editar" title="Editar"
                class="p-button-sm btn-color" @click="editarPerguntaReceberDados(data.all)"
              />

              <pm-Button label="Apagar"
                icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color" title="Apagar"
                @click="deletarPergunta(data.all.id)"   />

            </template>
          </pm-Column>

      </pm-DataTable>
    </div>
  </section>
</template>

<script>

import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Pergunta } from "@/class/pergunta.js";
import { Calendario } from "@/class/calendario";
import vueMask from 'vue-jquery-mask';
import store_token_info from "@/store/store_token_info.js";


export default {
  components: {

  },
  name: 'serie-Escolar',
  data () {
    const columns = [
      { key: "pergunta", label: "Pergunta", sortable: true },
      { key: "multipla_escolha", label: "A PERGUNTA É DE MULTIPLAS ESCOLHAS?", sortable: true },
      { key: "all", label: "Ação" },
    ];
    return {
      editarPerguntaHabilitado:0,
      filter: '',
      columns,
      perPage: 10,
      currentPage: 1,
      listaPerguntas: [],
      desativaPergunta: false,
      radioSelectedOption:"option1",
      perguntasCreche:false,
      serieInfo: {
        nome: null,
        segmento: '',
        segmento_id:null,
        tipo:null,
        user_id: store_token_info.usuario_logado,
        possui_mais_de_um_professor:false,
      },
      radioSelectedOption1:"option1",
      crechePerguntasInfo: {
        serie_id:'',
        pergunta:'',
        multipla_escolha:'',
      },
      segmentosALL: [],
      validationErrors: {}
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.listaPerguntas.length / this.perPage)
          : this.listaPerguntas.length
    },
  },
  methods: {
    voltar(){this.$router.push({name: 'series'});},
    async listaSegmentos() {
      try {
        let data = await SegmentoEscolar.obtemTodos();
        this.segmentosALL = data.data;
      } catch (e) {}
    },
    async cadastrar() {
      try {
        this.serieInfo.segmento_id = this.serieInfo.segmento.id;
        if (this.radioSelectedOption == "option1")
          this.serieInfo.tipo = 1;
        if (this.radioSelectedOption == "option2")
          this.serieInfo.tipo = 2;
        if (this.radioSelectedOption == "option3")
          this.serieInfo.tipo = 3;
        let data = await SeriesEscolar.cadastrar(this.serieInfo);
        if (this.radioSelectedOption != "option3") {
          this.$vaToast.init({
            message: "Cadastrado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            fullWidth: false,
          });
          this.$router.push({name: 'series'});
        }
        else {
          this.perguntasCreche = true;
          this.crechePerguntasInfo.serie_id = data.data.id;
        }
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);

          });
        }else{
          let a = [];
          a = e.response.data.erro;

          setTimeout(() => {
            this.$vaToast.init({
              message: a[0],
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3500,
              fullWidth: false,
              color: 'danger'
            });
          }, 500);
        }
      }
    },
    mudarSelecionado(segmento) {
        if (segmento.tipo == 0) {
          this.radioSelectedOption = "option3";
        }
        else {
          this.radioSelectedOption = "option1";
        }
    },
    async enviarPergunta() {
      try {
        this.crechePerguntasInfo.multipla_escolha = (this.radioSelectedOption1 == "option1" ? 1 : 0);
        let data = await Pergunta.cadastrar(this.crechePerguntasInfo);
        this.$vaToast.init({
            message: "Pergunta adicionada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'success',
            duration: 2500,
            fullWidth: false,
        });
        this.crechePerguntasInfo.pergunta = '';
        this.radioSelectedOption1 = "option1"
        this.buscarPerguntas();

      }
      catch(e) {
        this.$vaToast.init({
            message: "Problema ao editar a nota!",
            iconClass: 'fa-star-o',
            position: 'botton-left',
            color: 'danger',
            duration: 2500,
            fullWidth: false,
        });
      }
    },
    async deletarPergunta(id) {
      if (window.confirm("Você realmente deseja deletar a pergunta selecionada?")) {
        try {
          let data = await Pergunta.remover(id);
          this.$vaToast.init({
            message: "Pergunta deletada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'success',
            duration: 2500,
            fullWidth: false,
          });
          this.buscarPerguntas();
        }
        catch(e) {
          this.$vaToast.init({
            message: "Problema ao deletar a pergunta!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'danger',
            duration: 2500,
            fullWidth: false,
          });
        }
      }
    },
    async editarPerguntaReceberDados(all) {
      this.crechePerguntasInfo.id = all.id;
      this.crechePerguntasInfo.pergunta = all.pergunta
      this.crechePerguntasInfo.multipla_escolha = all.multipla_escolha;
      this.radioSelectedOption1 = (all.multipla_escolha == 1 ? "option1" : "option2");
      this.editarPerguntaHabilitado = !this.editarPerguntaHabilitado;

    },
    async editarPergunta() {
       try {
        this.crechePerguntasInfo.multipla_escolha = (this.radioSelectedOption1 == "option1" ? 1 : 0);
        let data = await Pergunta.alterar(this.crechePerguntasInfo);
        this.$vaToast.init({
            message: "Pergunta editada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'success',
            duration: 2500,
            fullWidth: false,
        });
        this.crechePerguntasInfo.pergunta = '';
        this.radioSelectedOption1 = "option1";
        this.editarPerguntaHabilitado = !this.editarPerguntaHabilitado;
        this.buscarPerguntas();

      }
      catch(e) {
        this.$vaToast.init({
            message: "Problema ao editar a pergunta",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'danger',
            duration: 2500,
            fullWidth: false,
        });
      }
    },
    async buscarPerguntas() {
      let data = await Pergunta.obtemTodos(this.crechePerguntasInfo.serie_id, this.anoLetivo);
      this.listaPerguntas = [];
      for (const el of data.data) {
        let novo = {
          id: el.id,
          pergunta: el.pergunta,
          multipla_escolha: (el.multipla_escolha == 1 ? "SIM" : "NÃO"),
          all: {
            id: el.id,
            pergunta: el.pergunta,
            multipla_escolha: el.multipla_escolha,
          }
        }
        this.listaPerguntas.push(novo);
      }
      this.listaPerguntas.reverse();
    },
  },
  mounted() {
    this.anoLetivo = sessionStorage.getItem("anoSelecionado");
    this.listaSegmentos();
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>